<template>
  <div class="finance">
    <!-- 背景图 -->
    <div class="bigimg-wraper">
      <div class="img-back">
        <div class="apply-wraper">
          <div class="apply-box">
            <!-- 二维码 -->
            <div class="ercode" v-if="ercodeDialog">
              <img :src="baseurl + ercodeimg" alt="" />
              <div class="toast" style="text-align:center">
                请扫描二维码完成提交
              </div>
            </div>

            <div class="title">定制金融购车方案</div>
            <div class="phone-input">
              <el-input
                v-model="phoneNumber"
                placeholder="请输入您的手机号码"
              ></el-input>
              <div class="code" @click="sendSms">{{ codeText }}</div>
            </div>
            <div class="code-input">
              <el-input
                v-model="codeNumber"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="apply-btn" @click="getProgramQrcode">立即申请</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="process">
        <div class="process-item" v-for="(item, index) in process" :key="index">
          <div class="left">
            <img :src="require('@/static/finance/' + item.icon + '.png')" alt />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="msg">{{ item.msg }}</div>
          </div>
        </div>
      </div>
      <div class="con">
        <!-- 受欢迎的 -->
        <div class="popular">
          <div class="commen-title">受欢迎的分期购车</div>
          <div class="popular-con">
            <div
              class="popular-list"
              v-for="(item, index) in popular"
              :key="index"
            >
              <div class="title">{{ item.title }}</div>
              <div class="info">{{ item.info }}</div>
              <div class="img">
                <img :src="item.imgurl" alt />
              </div>
            </div>
          </div>
        </div>
        <!-- 分期 -->
        <div class="stage">
          <div class="commen-title">分期流程</div>
          <div class="stage-con">
            <div class="stage-list" v-for="(item, index) in stage" :key="index">
              <div class="icon-box">
                <img
                  :src="require('@/static/finance/' + item.imgurl + '.png')"
                  alt
                />
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="info" v-html="item.info"></div>
            </div>
          </div>
        </div>
        <!-- 合作机构 -->
        <!-- <div class="agencies">
          <div class="commen-title">合作机构</div>
          <div class="agencies-con">
            <div
              class="agencies-list"
              v-for="(item, index) in agencies"
              :key="index"
            >
              <img src alt />
            </div>
          </div>
        </div> -->

        <!-- <div class="apply-now" @click="goTop">立即申请</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { sendSms, getProgramQrcode, closeClient } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      process: [
        { icon: "52", title: "更多选择", msg: "多款精选金融方案" },
        { icon: "51", title: "更快审核", msg: "最快30分钟完成审批" },
        { icon: "50", title: "更高通过率", msg: "专属制定，加速审核通过率" },
        { icon: "49", title: "更精准服务", msg: "1对1 金融专家全程跟进服务" },
      ],
      popular: [
        {
          title: "首付2成起",
          info: "豪车开回家",
          imgurl: "https://www.yuexinche.com/pic/car1.png",
        },
        {
          title: "高端车款品质保证",
          info: "金融购车更灵活",
          imgurl: "https://www.yuexinche.com/pic/car2.png",
        },
        {
          title: "拥有奔驰宝马",
          info: "超低年化率",
          imgurl: "https://www.yuexinche.com/pic/car3.png",
        },
        {
          title: "全国可购车",
          info: "金融服务范围广",
          imgurl: "https://www.yuexinche.com/pic/car4.png",
        },
      ],
      stage: [
        {
          imgurl: "30",
          title: "即刻申请",
          info: "需要分期购车<br>提交基本信息即可申请",
        },
        {
          imgurl: "31",
          title: "预约看车",
          info: "在线挑选悦心车<br>预约顾问一对一全程陪同讲解",
        },
        {
          imgurl: "32",
          title: "极速审批",
          info: "资料提交后立即审批<br>最快30分钟出结果",
        },
        {
          imgurl: "33",
          title: "放款提车",
          info: "悦心车助您完成车辆过户<br>抵押等手续后，当天放款",
        },
      ],
      agencies: ["1", "2", "3", "4", "5", "6", "7"],
      // 弹框
      phoneNumber: "",
      codeNumber: "",
      codeKey: false, //发送验证锁
      codeText: "获取验证码",
      timer: null,
      session_id: "",
      ercodeimg: "", //二维码图片
      ercodeDialog: false, //弹框
    };
  },
  methods: {
    // 底部立即申请
    goTop() {
      window, scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    // 发送验证码
    sendSms() {
      const reg = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
      if (!reg.test(this.phoneNumber)) {
        return this.$Message.warning("请输入正确的手机号");
      }
      if (this.codeKey) return;
      this.codeKey = true;
      this.countDown();
      sendSms({
        mobile: this.phoneNumber,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$Message.success("发送成功");
            this.session_id = res.data.session_id;
            this.codeNumber = res.data.codeNumber;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          this.codeText = "发送验证码";
          this.timer && clearInterval(this.timer);
          this.$Message.error(err.msg);
        });
    },
    // 倒计时
    countDown() {
      let time = 59;
      this.codeText = `${time}s`;
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        time--;
        if (time == 0) {
          clearInterval(this.timer);
          this.codeKey = false;
          this.codeText = "发送验证码";
          return;
        }
        this.codeText = `${time}s`;
      }, 1000);
    },
    // 获取注册小程序码(官网)
    getProgramQrcode() {
      let that = this;
      let ws = new WebSocket("wss://www.yuexinche.com/wss");
      ws.onopen = function() {
        setInterval(show, 30000);
      };
      function show() {
        ws.send("heart beat\n");
      }
      // 服务端主动推送消息时会触发这里的onmessage
      ws.onmessage = function(e) {
        // json数据转换成js对象
        var data = eval("(" + e.data + ")");
        var type = data.type || "";
        switch (type) {
          // 初始化
          case "init":
            getProgramQrcode({
              session_id: that.session_id,
              client_id: data.client_id,
              mobile: that.phoneNumber,
              code: that.codeNumber,
            })
              .then((res) => {
                if (res.code == 0) {
                  that.ercodeDialog = true;
                  that.ercodeimg = res.data.src;
                }
              })
              .catch((err) => {
                that.$message(err.msg);
                that.codedialog = false;
              });
            break;
          //心跳保持
          case "heart":
            // console.log(data.client_id + "发送了" + data.message);
            break;
          case "msg":
            if (data.code == -1) {
              return
            } else if (data.code == 0) {
              //以下是业务代码(将获取到的用户信息展示在页面上)
              localStorage.setItem("userInfo", JSON.stringify(data.message));
              that.userInfo = JSON.parse(localStorage.getItem("userInfo"));
              that.$Message.success({
                content: "提交成功，我们将于24小时内联系您",
                duration: "3",
                top:'300',
                onClose: () => {
                  window.location.reload();
                },
              });
            } else {
              return;
            }
            break;
          default:
            break;
        }

        //600秒未扫码则关闭长连接
        setTimeout(function() {
          closeClient({ client_id: data.client_id }).then(() => {
            // that.codedialog = false;
          });
        }, 1000 * 600);
      };
    },
  },
};
</script>

<style lang="less" scoped>
.finance {
  width: 100%;
  background-color: #fff;
  padding-bottom: 50px;
  box-sizing: border-box;
  .bigimg-wraper {
    width: 100%;
    height: 400px;
    background-color: #0d61a6;
    .img-back {
      margin: 0 auto;
      width: 100%;
      min-width: 1200px;
      max-width: 1920px;
      height: 400px;
      padding-top: 60px;
      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-image: url("https://www.yuexinche.com/pic/pc5.jpg");
      .apply-wraper {
        width: 1200px;
        height: 245px;
        margin: 0 auto;
        padding-right: 60px;
        box-sizing: border-box;
        .apply-box {
          width: 310px;
          height: 245px;
          background-color: #fff;
          padding: 20px;
          box-sizing: border-box;
          float: right;
          position: relative;
          .ercode {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #fff;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            z-index: 10;
            img {
              display: block;
              max-width: 80%;
              max-height: 80%;
              margin: 15px auto 0;
            }
          }
          .title {
            font-size: 14px;
            color: #333;
          }
          .phone-input {
            width: 100%;
            height: 40px;
            margin-top: 20px;
            position: relative;
            .code {
              width: 80px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              border: 1px solid rgba(0, 151, 226, 1);
              border-radius: 12px;
              color: #0097e2;
              position: absolute;
              right: 5px;
              top: 8px;
              font-size: 12px;
            }
            /deep/ .el-input__inner {
              font-size: 12px;
              padding-right: 90px;
            }
          }
          .code-input {
            width: 100%;
            height: 40px;
            margin-top: 10px;
            /deep/ .el-input__inner {
              font-size: 12px;
            }
          }
          .apply-btn {
            width: 100%;
            height: 46px;
            background-color: #0097e2;
            border-radius: 4px;
            color: #fff;
            font-size: 16px;
            line-height: 46px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .content {
    width: 1200px;
    min-width: 1200px;
    height: auto;
    background-color: #fbfbfb;
    position: relative;
    top: -60px;
    margin: 0 auto;
    padding-bottom: 130px;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .process {
      height: 155px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      .process-item {
        width: auto;
        height: 40px;
        display: flex;
        .left {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .right {
          .title {
            width: auto;
            height: 20px;
            font-size: 18px;
            line-height: 20px;
            color: #666666;
          }
          .msg {
            height: 20px;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
          }
        }
      }
    }
    .con {
      width: 900px;
      height: auto;
      margin: 0 auto;
      .popular {
        width: 100%;
        .commen-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #d2d2d2;
          font-size: 21px;
          color: #333;
          font-weight: bold;
        }
        .popular-con {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 60px;
          box-sizing: border-box;
          .popular-list {
            width: 23%;
            .title {
              font-size: 14px;
              color: #333;
              margin-top: 30px;
            }
            .info {
              font-size: 12px;
              color: #0097e2;
              margin-top: 10px;
            }
            .img {
              width: 100%;
              height: 146px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }

      .stage {
        width: 100%;
        .commen-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #d2d2d2;
          font-size: 21px;
          color: #333;
          font-weight: bold;
        }
        .stage-con {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 60px;
          box-sizing: border-box;
          .stage-list {
            width: 23%;
            padding-top: 50px;
            box-sizing: border-box;
            text-align: center;
            .icon-box {
              width: 56px;
              height: 56px;
              margin: 0 auto;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .title {
              font-size: 18px;
              color: #333;
              margin-top: 20px;
            }
            .info {
              font-size: 14px;
              color: #666;
              margin-top: 20px;
            }
          }
        }
      }

      .agencies {
        width: 100%;
        .commen-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #d2d2d2;
          font-size: 21px;
          color: #333;
          font-weight: bold;
        }
        .agencies-con {
          width: 100%;
          display: flex;
          padding-bottom: 60px;
          box-sizing: border-box;
          padding-top: 10px;
          flex-wrap: wrap;
          .agencies-list {
            width: 19%;
            height: 66px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.25%;
            margin-top: 20px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }

      .apply-now {
        width: 190px;
        height: 42px;
        line-height: 42px;
        font-size: 21px;
        color: #fff;
        text-align: center;
        background-color: #0097e2;
        border-radius: 4px;
        margin: 0 auto;
      }
    }
  }
}
</style>
